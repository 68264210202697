import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Color, BaseChartDirective, Label } from "ng2-charts";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { DecimalPipe } from "@angular/common";
import { Platform } from "@ionic/angular";

import { DatabaseService } from "../database.service";
import { DepositActivity } from "src/app/models/deposit-activity";
import { Deposit } from "src/app/models/deposit";

import * as pluginAnnotations from "chartjs-plugin-annotation";
import * as moment from "moment/moment";
import * as Chart from "chart.js";

@Component({
    selector: "deposit-overview-table",
    styleUrls: ["./deposit-overview-table.component.scss"],
    templateUrl: "./deposit-overview-table.component.html",
})
export class DepositOverviewTableComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    private destroySub = new Subject<void>();
    private refreshToken = new Subject<void>();
    private numberOfPointsToShow: number;

    private readonly months = [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
    ];

    @Input()
    refresh: boolean;

    @Input()
    deposit: Deposit;

    @Input()
    depositsList: string[];

    @Input()
    currency?: "USD" | "ILS" = "ILS";

    @Input()
    currencyRate?: number;

    @Output()
    currentMonthTendency: EventEmitter<number> = new EventEmitter<number>();

    depositActivites$: Observable<DepositActivity[]>;
    depositActivites: DepositActivity[];

    currentYear = new Date().getFullYear();

    dataSource: any;

    currencySymbol = "₪";

    isLoading = true;

    lineChartData: ChartDataSets[] = [{ data: [] }];

    lineChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: (label) => {
                    if (this.currency === "USD") {
                        return `(₪${(+label.value * this.currencyRate).toFixed(
                            2
                        )}) ${this.currencySymbol}${this.decPipe.transform(
                            label.value
                        )}`;
                    }

                    return `${this.currencySymbol}${this.decPipe.transform(
                        label.value
                    )}`;
                },
            },
        },
        scales: {
            gridLines: {
                tickMarkLength: 0,
            },
            xAxes: [
                {
                    id: "x-axis-0",
                    ticks: {
                        padding: 15,
                    },
                },
            ],
            yAxes: [
                {
                    id: "y-axis-0",
                    position: "left",
                    stacked: false,
                    ticks: {
                        padding: 5,
                        beginAtZero: false,
                        callback: (val) =>
                            `${this.currencySymbol}${this.decPipe.transform(
                                val
                            )}`,
                    },
                },
            ],
        },
        animation: {
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.font = Chart.helpers.fontString(
                    11,
                    Chart.defaults.global.defaultFontStyle,
                    Chart.defaults.global.defaultFontFamily
                );
                ctx.textAlign = "right";
                ctx.textBaseline = "middle";

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data: any = Number(
                            dataset.data[index] - (dataset.data[index - 1] ?? 0)
                        );
                        ctx.fillStyle = data > 0 ? "green" : "red";
                        data =
                            data > 0
                                ? data.toFixed(2) + "+"
                                : Math.abs(data.toFixed(2)) + "-";
                        ctx.fillText(
                            `${
                                chartInstance.options.circumference === 1
                                    ? "$"
                                    : "₪"
                            }${data
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                            bar._model.x + 3,
                            bar._model.y + 15
                        );
                    });
                });
            },
        },
    };

    lineChartColors: Color[] = [
        {
            backgroundColor: "transparent",
            borderColor: "rgba(0,0,0,0.1)",
            pointBackgroundColor: "rgba(0,0,0,0.1)",
            pointBorderColor: "rgba(0,0,0,0.1)",
            pointHoverBackgroundColor: "rgba(0,0,0,0.1)",
            pointHoverBorderColor: "rgba(0,0,0,0.1)",
            pointHoverRadius: 5,
            pointRadius: 4,
        },
    ];

    lineChartType = "line" as ChartType;
    lineChartLabels: Label[] = [];
    lineChartPlugins = [pluginAnnotations];

    constructor(
        private platform: Platform,
        private decPipe: DecimalPipe,
        private db: DatabaseService
    ) {}

    doRefresh(event): void {
        this.refreshToken.next();
        event.target.complete();
    }

    updateDataSource(activites: DepositActivity[]): void {
        let depositsPerMonth = new Map<string, number>();
        this.dataSource = [];

        activites = activites.sort(function (
            a: DepositActivity,
            b: DepositActivity
        ) {
            let aDate = moment(a.activityDate).toDate();
            let bDate = moment(b.activityDate).toDate();
            return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
        });

        for (let i = 0; i < activites.length; i++) {
            let date = moment(activites[i].activityDate).toDate();
            let key = `${this.months[date.getMonth()]} ${date.getFullYear()}`;

            if (depositsPerMonth.has(key)) {
                depositsPerMonth.set(
                    key,
                    +depositsPerMonth.get(key) + activites[i].activityAmount
                );
            } else {
                depositsPerMonth.set(key, activites[i].activityAmount);
            }
        }

        for (let [k, v] of depositsPerMonth.entries()) {
            this.dataSource.push({ timestamp: k, amount: v });
        }

        let total = 0.0;
        let data = this.dataSource
            .map(function (d) {
                total += +Number(d.amount).toFixed(2);
                return +Number(total).toFixed(2);
            })
            .filter((l) => Boolean(l));

        if (data.length > this.numberOfPointsToShow) {
            data = data.slice(
                data.length - this.numberOfPointsToShow,
                data.length
            );
        }

        this.lineChartData = [
            {
                data,
                pointRadius: 4,
            },
        ];

        if (this.dataSource.length > this.numberOfPointsToShow) {
            this.dataSource = this.dataSource.slice(
                this.dataSource.length - this.numberOfPointsToShow,
                this.dataSource.length
            );
        }

        this.lineChartLabels = this.dataSource
            .map(function (d) {
                return d.timestamp;
            })
            .filter((l) => l != undefined);

        // update current month tendency
        if (this.dataSource?.length) {
            const nowDate = new Date();
            const currentDateLabel = `${
                this.months[nowDate.getMonth()]
            } ${nowDate.getFullYear()}`;
            const lastRecord = this.dataSource[this.dataSource.length - 1];
            this.currentMonthTendency.next(
                lastRecord?.timestamp === currentDateLabel
                    ? lastRecord.amount
                    : 0
            );
        }
    }

    isMobileDevice(): boolean {
        return !this.platform.is("desktop") && !document.URL.startsWith("http");
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refreshToken.next();
    }

    ngOnInit(): void {
        this.numberOfPointsToShow = this.isMobileDevice() ? 6 : 12;
        this.lineChartOptions.circumference = 0;

        if (this.currency === "USD" && this.currencyRate) {
            this.currencySymbol = "$";
            this.lineChartOptions.circumference = 1;
        }

        if (!this.depositsList) {
            this.depositActivites$ = this.refreshToken.pipe(
                switchMap(() =>
                    this.db.getDepositActivites(this.deposit.depositId)
                ),
                tap(
                    (activites: DepositActivity[]) =>
                        (this.depositActivites = activites)
                ),
                tap((activites: DepositActivity[]) =>
                    this.updateDataSource(activites)
                ),
                tap(() => (this.isLoading = false)),
                takeUntil(this.destroySub)
            );
        } else {
            this.depositActivites$ = this.refreshToken.pipe(
                switchMap(() =>
                    this.db.getDepositTotalActivites(this.depositsList)
                ),
                tap(
                    (activites: DepositActivity[]) =>
                        (this.depositActivites = activites)
                ),
                tap((activites: DepositActivity[]) =>
                    this.updateDataSource(activites)
                ),
                tap(() => (this.isLoading = false)),
                takeUntil(this.destroySub)
            );
        }

        this.depositActivites$.subscribe();
    }

    ngAfterViewInit(): void {
        this.refreshToken.next();
    }

    ngOnDestroy(): void {
        this.destroySub.next();
        this.destroySub.complete();
        this.refreshToken.complete();
        this.currentMonthTendency.unsubscribe();
    }
}
