export const environment = {
    production: false,
    version: "09/04/2025",
    apnAuthKey: "65CDQ492B7",
    firebase: {
        apiKey: "AIzaSyD0WlLmke9C_udXkhKh94TPfmrNsjAouR8",
        authDomain: "tashir-financial-tracking-ion.firebaseapp.com",
        projectId: "tashir-financial-tracking-ion",
        storageBucket: "tashir-financial-tracking-ion.appspot.com",
        messagingSenderId: "363599767919",
        appId: "1:363599767919:web:8049589453f3d7c4e7209a",
    },
};
